import React, { useCallback } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button, Modal } from 'react-bootstrap';
import { getVideoDevices } from 'utils';

const Settings = ({ userSettings, setUserSettings }) => {
  const toggleSetting = useCallback(
    (settingName) => {
      setUserSettings((prevSettings) => ({
        ...prevSettings,
        [settingName]: !prevSettings[settingName],
      }));
    },
    [setUserSettings]
  );

  return (
    <div className="settingsArea my-2">
      <div className="col-12 bg-white p-2">
        <Tab.Container defaultActiveKey="admin">
          <Nav variant="pills" className="nav-fill">
            <Nav.Item>
              <Nav.Link variant="secondary" eventKey="advanced" className="settingsPill">
                <i className="fa-sharp fa-solid fa-sliders-up me-2" />
                Advanced
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link variant="secondary" eventKey="admin" className="settingsPill">
                <i className="fa-sharp fa-solid fa-lock-keyhole me-2" />
                Admin
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="advanced">
              <Container>
                <Row>
                  <Col className="d-flex justify-content-center" xs={12} md={6}>
                    <Form>
                      <Form.Switch
                        id="internalViewerSwitch"
                        label="Use Internal PDF Viewer"
                        checked={userSettings.internalViewer}
                        onChange={() => toggleSetting('internalViewer')}
                      />
                    </Form>
                  </Col>
                  <Col className="d-flex justify-content-center" xs={12} md={6}>
                    <Form.Switch
                      id="showPhotosSwitch"
                      label="Show Photos"
                      checked={userSettings.showPhotos}
                      onChange={() => toggleSetting('showPhotos')}
                    />
                  </Col>
                  <Col className="d-flex justify-content-center" xs={12} md={6}>
                    <CameraList userSettings={userSettings} setUserSettings={setUserSettings} />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="admin">
              <Button variant="primary" href="/admin">
                Go to Admin
              </Button>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

const CameraList = ({ userSettings, setUserSettings }) => {
  const [availableDevices, setAvailableDevices] = React.useState(userSettings.devices);
  const [showModal, setShowModal] = React.useState(false);

  const requestCameraPermissions = useCallback(async () => {
    if (availableDevices.length === 0) {
      try {
        const devices = await getVideoDevices();
        console.log('Devices:', devices);
        if (devices.rearCameras.length > 0) {
          const rearCameras = devices.rearCameras.map((device) => ({
            deviceId: device.deviceId,
            label: device.label,
          }));
          setAvailableDevices(rearCameras);
          if (devices.defaultCamera) {
            setUserSettings((prev) => ({ ...prev, camera: devices.defaultCamera.deviceId }));
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setAvailableDevices('denied');
        setShowModal(true);
      }
    }
  }, [availableDevices.length, setUserSettings]);

  const handleCameraChange = useCallback(
    (e) => {
      setUserSettings((prev) => ({ ...prev, camera: e.target.value }));
    },
    [setUserSettings]
  );

  // Ensure camera value is never null
  const cameraValue = userSettings.camera || '';

  return (
    <>
      {Array.isArray(availableDevices) ? (
        <Form.Select id="cameraSelect" label="Camera" onClick={requestCameraPermissions} value={cameraValue} onChange={handleCameraChange}>
          <option value="">Select a camera</option>
          {availableDevices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          ))}
        </Form.Select>
      ) : (
        <>
          <p style={{ color: 'red' }}>Camera Permissions denied</p>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Camera Permissions Denied</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Camera permissions were previously denied. Please grant camera access to use this feature.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default Settings;
