import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import { getColumnDefinitions } from './columnDefinitions';
import ColumnVisibilityToggle from './ColumnVisibilityToggle';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import PaginationComponent from '../sub-components/Pagination';
import './LifecycleTable.css'; // Import the CSS file

const LifecycleTable = ({
  data = [],
  tableType,
  pageIndex,
  pageSize,
  setPageIndex,
  setPageSize,
  portalConfigs,
  onAddFilter,
  columnVisibility,
  setColumnVisibility,
}) => {
  const [rowSelection, setRowSelection] = useState({});

  // Memoize columns to prevent re-creation on every render
  const columns = useMemo(() => getColumnDefinitions(tableType, portalConfigs, onAddFilter), [tableType, portalConfigs, onAddFilter]);

  // Handle page size change with useCallback to prevent re-creation
  const handlePageSizeChange = useCallback(
    (newPageSize) => {
      setPageSize(newPageSize);
      setPageIndex(0); // Reset to first page when changing page size
    },
    [setPageSize, setPageIndex]
  );

  // Handle page index change with useCallback
  const handlePageChange = useCallback(
    (newPageIndex) => {
      setPageIndex(newPageIndex);
    },
    [setPageIndex]
  );

  // Memoize table instance to avoid unnecessary recalculations
  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      rowSelection,
      pagination: { pageIndex, pageSize },
      columnVisibility,
    },
    onRowSelectionChange: setRowSelection,
    onPaginationChange: ({ pageIndex, pageSize }) => {
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    },
    onColumnVisibilityChange: setColumnVisibility,
    enableRowSelection: true,
    pageCount: Math.ceil((data?.length || 0) / pageSize),
  });

  // Initialize column visibility if not already set
  useEffect(() => {
    if (Object.keys(columnVisibility).length === 0) {
      const initialVisibility = table.getAllLeafColumns().reduce((acc, column) => {
        acc[column.id] = column.getIsVisible();
        return acc;
      }, {});
      setColumnVisibility(initialVisibility);
    }
  }, [columnVisibility, setColumnVisibility, table]);

  // Return early if no data is available
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="table-wrapper">
      <div className="table-container">
        <div className="d-flex justify-content-end mb-2">
          <ColumnVisibilityToggle
            columns={table.getAllLeafColumns()}
            columnVisibility={columnVisibility}
            onColumnVisibilityChange={setColumnVisibility}
          />
        </div>

        <Table striped bordered hover responsive>
          <TableHeader headerGroups={table.getHeaderGroups()} />
          <TableBody rows={table.getRowModel().rows} />
        </Table>

        <div className="sticky-pagination">
          <PaginationComponent
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            pageSize={table.getState().pagination.pageSize}
            totalEntries={data.length}
            onPageChange={handlePageChange}
            previousPage={() => handlePageChange(table.getState().pagination.pageIndex - 1)}
            nextPage={() => handlePageChange(table.getState().pagination.pageIndex + 1)}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default LifecycleTable;
