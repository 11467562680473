import React from 'react';
import { Pagination } from 'react-bootstrap';
//import the pagination component css
import './Pagination-Bar.css';

const PaginationBar = ({ currentPage, totalPages, totalDocuments, handlePageChange, hideMobile }) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handleFirstPage = () => {
    // Logic to handle first page button click
    handlePageChange(1);
  };

  const handlePreviousPage = () => {
    // Logic to handle previous page button click
    handlePageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    // Logic to handle next page button click
    handlePageChange(currentPage + 1);
  };

  const handleLastPage = () => {
    // Logic to handle last page button click
    handlePageChange(totalPages);
  };

  return (
    <Pagination className={`my-1 ${hideMobile ? 'd-none d-lg-flex' : 'mb-0'}`} id={hideMobile ? '' : 'bottomPagination'}>
      <Pagination.Item disabled={isFirstPage} onClick={handleFirstPage}>
        <i className="fa-light fa-backward-step" />
      </Pagination.Item>
      <Pagination.Item disabled={isFirstPage} onClick={handlePreviousPage}>
        <i className="fa-light fa-backward" />
      </Pagination.Item>
      <Pagination.Item
        active="false"
        linkClassName="text-dark bg-light border-0"
        className="d-flex flex-fill bg-light justify-content-center align-items-center"
      >
        <span>
          <span className="d-none d-sm-inline">{`Page `}</span>
          {`${currentPage} of ${totalPages}`}
        </span>

        <span className="d-none d-md-inline ms-2">{`(${totalDocuments} Results)`}</span>
      </Pagination.Item>
      <Pagination.Item disabled={isLastPage} onClick={handleNextPage}>
        <i className="fa-light fa-forward" />
      </Pagination.Item>
      <Pagination.Item disabled={isLastPage} onClick={handleLastPage}>
        <i className="fa-light fa-forward-step" />
      </Pagination.Item>
    </Pagination>
  );
};

export default PaginationBar;
