import React, { useState, useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { PortalContext } from 'context/Portal';
import './Locations.css'; // Import the CSS file

const Location = ({ location, children, onAddFilter }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleClick = () => {
    onAddFilter('locations', location.locationID, `${location.name}`);
  };

  const getBoxShadow = (children) => {
    const colors = [...new Set(children.map((child) => child.color))].slice(0, 5);
    const bootstrapColors = {
      primary: '#0d6efd',
      secondary: '#6c757d',
      success: '#198754',
      danger: '#dc3545',
      warning: '#ffc107',
      info: '#0dcaf0',
      light: '#f8f9fa',
      dark: '#212529',
    };

    const getColorHex = (color) => bootstrapColors[color] || color;
    const colorsHex = colors.map(getColorHex);
    return colorsHex.map((color, index) => `${(index + 1) * 2}px ${(index + 1) * 2}px 0 ${color}`).join(', ');
  };

  const boxShadow = children.length > 0 && !isExpanded ? getBoxShadow(children) : 'none';

  return (
    <div>
      <Badge bg={location.color} onClick={handleClick} style={{ cursor: 'pointer', boxShadow, border: '1px solid white' }}>
        {location.name}
        {children.length > 0 && (
          <span onClick={toggleExpand} style={{ marginLeft: '5px', cursor: 'pointer' }}>
            {isExpanded ? '-' : '+'}
          </span>
        )}
      </Badge>
      {isExpanded && (
        <div style={{ marginLeft: '20px' }}>
          {children.map((child) => (
            <Location key={child.locationID} location={child} children={child.children} onAddFilter={onAddFilter} />
          ))}
        </div>
      )}
    </div>
  );
};

const Locations = ({ locationIDs, onAddFilter }) => {
  const { locations } = useContext(PortalContext);

  const buildHierarchy = (locationIDs) => {
    const locationMap = {};
    locationIDs.forEach((id) => {
      const location = Object.values(locations).find((loc) => loc.locationID === id);
      if (location) {
        // Check if location exists
        location.children = [];
        locationMap[id] = location;
      }
    });

    const rootLocations = [];
    locationIDs.forEach((id) => {
      const location = locationMap[id];
      if (location) {
        // Check if location exists
        if (location.parent && locationMap[location.parent]) {
          locationMap[location.parent].children.push(location);
        } else {
          rootLocations.push(location);
        }
      }
    });

    return rootLocations;
  };

  const rootLocations = buildHierarchy(locationIDs);

  return (
    <div className="location-list p-1">
      {rootLocations.map((location) => (
        <Location key={location.locationID} location={location} children={location.children} onAddFilter={onAddFilter} />
      ))}
    </div>
  );
};

export default Locations;
