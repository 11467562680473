import { useState } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

export const useGet = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { keycloak } = useKeycloak();

  const apiURLPrefix = process.env.NODE_ENV !== 'production' ? '' : '/api';
  const subdomain = window.location.hostname.split('.')[0];

  const getData = async (path, options = {}) => {
    try {
      const headers = {
        subdomain,
        ...(keycloak.authenticated && { Authorization: `Bearer ${keycloak.token}` }),
      };

      const response = await axios.get(`${apiURLPrefix}${path}`, { ...options, headers });
      setData(response.data);
      return response;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDocument = async (pdfRendered) => {
    const documentURL = pdfRendered.fileLocation === undefined ? `/sds/${pdfRendered.documentID}` : `/documents/${pdfRendered.fileLocation}`;
    await getData(documentURL, { responseType: 'arraybuffer' });
  };

  const getImage = async (urlFragment) => {
    const imageURL = `/resources/${subdomain}/${urlFragment}`;
    await getData(imageURL);
  };

  return { getDocument, getImage, getData, data, isLoading, error };
};
