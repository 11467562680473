import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import Images from './sub-components/Images';
import SignalWord from './sub-components/SignalWord';
import Pictogram from './sub-components/Pictogram';
import Locations from './sub-components/Locations';
import Actions from './sub-components/Actions';
import SdsDownload from './sub-components/SdsDownload';
import { StringCell } from 'components/custom-attributes/String';
import { NumberCell } from 'components/custom-attributes/Number';
import { MultiSelectCell } from 'components/custom-attributes/Multi-Select';
import { TextAreaDisplay } from 'components/custom-attributes/TextArea';
import { Button } from 'react-bootstrap';

const columnHelper = createColumnHelper();

// Define a mapping of attribute types to their corresponding components
const attributeComponents = {
  String: StringCell,
  Number: NumberCell,
  'multi-select': MultiSelectCell,
  TextArea: TextAreaDisplay,
};

// Export a function to get column definitions based on table name
export const getColumnDefinitions = (tableName, portalConfigs, onAddFilter) => {
  // Define individual columns
  const selectColumn = columnHelper.display({
    id: 'select',
    header: ({ table }) => <input type="checkbox" checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />,
    cell: ({ row }) => <input type="checkbox" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />,
    enableSorting: false,
    enableHiding: false,
  });

  const recordIdColumn = columnHelper.accessor('recordId', {
    header: 'SDS ID',
    cell: (info) => info.getValue(),
  });

  const photosColumn = columnHelper.accessor('photos', {
    header: 'Images',
    cell: (info) => <Images urls={info.getValue()} />,
    textAlign: 'center',
  });

  const productIdentifierColumn = columnHelper.accessor('productIdentifier', {
    header: 'Chemical Name',
    cell: (info) => info.getValue(),
  });

  const akaColumn = columnHelper.accessor('aka', {
    header: 'AKA',
    cell: (info) => (Array.isArray(info.getValue()) ? info.getValue().join(', ') : info.getValue()),
  });

  const manNameColumn = columnHelper.accessor('manName', {
    header: 'Manufacturer',
    cell: (info) => info.getValue(),
  });

  const signalWordColumn = columnHelper.accessor('signalWord', {
    header: 'Signal Word',
    cell: (info) => <SignalWord word={info.getValue()} />,
    textAlign: 'center',
  });

  const pictogramsColumn = columnHelper.accessor('pictograms', {
    header: 'Pictograms',
    cell: (info) => {
      const pictograms = info.getValue();
      return Array.isArray(pictograms) ? pictograms.map((ghs, index) => <Pictogram key={index} ghs={ghs} onAddFilter={onAddFilter} />) : null;
    },
    textAlign: 'center',
  });

  const locationsColumn = columnHelper.accessor('locations', {
    header: 'Locations',
    cell: (info) => <Locations locationIDs={info.getValue()} onAddFilter={onAddFilter} />,
  });

  const sdsColumn = columnHelper.accessor('sds', {
    header: 'SDS',
    cell: (info) => <SdsDownload record={info.row.original} />, // Pass the entire row object
    textAlign: 'center',
  });

  const actionsColumn = columnHelper.accessor('actions', {
    header: 'Actions',
    cell: (info) => <Actions record={info.row.original} />, // Pass the entire row object
  });

  //request columns
  const requestorNameColumn = columnHelper.accessor('requestorName', {
    header: 'Requestor',
    cell: (info) => info.getValue(),
  });

  const requestorPhoneColumn = columnHelper.accessor('requestorPhone', {
    header: 'Phone',
    cell: (info) => info.getValue(),
  });

  const requestDetailsColumn = columnHelper.accessor('requestDetails', {
    header: 'Request Details',
    cell: (info) => info.getValue(),
  });

  // Define column configurations for different tables
  const columnConfigs = {
    activeTable: [
      selectColumn,
      recordIdColumn,
      photosColumn,
      productIdentifierColumn,
      akaColumn,
      manNameColumn,
      signalWordColumn,
      pictogramsColumn,
      locationsColumn,
      sdsColumn,
      actionsColumn,
    ],
    pendingTable: [
      selectColumn,
      photosColumn,
      productIdentifierColumn,
      akaColumn,
      manNameColumn,
      requestorNameColumn,
      requestorPhoneColumn,
      requestDetailsColumn,
      actionsColumn,
    ],
    inProcessTable: [
      selectColumn,
      photosColumn,
      productIdentifierColumn,
      akaColumn,
      manNameColumn,
      signalWordColumn,
      pictogramsColumn,
      locationsColumn,
      actionsColumn,
    ],
    archivedTable: [
      selectColumn,
      recordIdColumn,
      photosColumn,
      productIdentifierColumn,
      akaColumn,
      manNameColumn,
      signalWordColumn,
      pictogramsColumn,
      locationsColumn,
      actionsColumn,
    ],
  };

  let columns = columnConfigs[tableName] || [];

  if (portalConfigs?.portal?.attributeSettings) {
    const customAttributes = portalConfigs.portal.attributeSettings;
    const customColumns = Object.entries(customAttributes).map(([key, attr]) =>
      columnHelper.accessor(key, {
        header: attr.name || key,
        cell: (info) => {
          const row = info.row.original;
          const customAttrValue = row[attr.name];
          if (customAttrValue !== undefined) {
            const Component = attributeComponents[attr.type];
            return Component ? <Component value={customAttrValue} columnName={attr.name} onAddFilter={onAddFilter} /> : customAttrValue;
          }
          return null;
        },
      })
    );

    // Insert custom columns before the last column (usually 'actions')
    columns = [...columns.slice(0, -1), ...customColumns, columns[columns.length - 1]];
  }

  return columns;
};
