import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import NavigationBar from './sub-components/NavigationBar';
import LifecycleBar from './sub-components/LifecycleBar';
import LifecycleTable from './tables/LifecycleTable';
import PhotoSwipe from '../portal/pdf-viewer/Photoswipe';
import { useGet } from '../../hooks/useGet';
import { useKeycloak } from '@react-keycloak/web';
import { PortalContext } from '../../context/Portal';
import { setupWebSocket } from '../../utils/setupWebSocket';
import TableFilters from './tables/TableFilter';
import Fuse from 'fuse.js';
import '../../Admin.css';
import './Admin.css';

// Helper functions for highlighting (copied from Paginated-SDS-Item.js)
const highlightTextOrArray = (textOrArray, fieldMatches) => {
  if (Array.isArray(textOrArray)) {
    return highlightTextArray(textOrArray, fieldMatches);
  } else if (React.isValidElement(textOrArray)) {
    return textOrArray;
  } else if (typeof textOrArray === 'object' && textOrArray !== null) {
    try {
      return highlightText(JSON.stringify(textOrArray), fieldMatches);
    } catch (error) {
      return '[Complex Object]';
    }
  } else {
    return highlightText(String(textOrArray), fieldMatches);
  }
};

const highlightTextArray = (textArray, fieldMatches) => {
  return (
    <React.Fragment>
      {textArray.map((item, index) => {
        let displayItem;
        if (React.isValidElement(item)) {
          displayItem = item;
        } else if (typeof item === 'object' && item !== null) {
          try {
            displayItem = JSON.stringify(item);
          } catch (error) {
            displayItem = '[Complex Object]';
          }
        } else {
          displayItem = String(item);
        }

        const itemMatches = fieldMatches.filter((match) => {
          if (React.isValidElement(displayItem)) {
            // Skip matching for React elements
            return false;
          }
          return match.indices.some(([start, end]) => {
            const substring = match.value.substring(start, end + 1);
            return String(displayItem).includes(substring) && substring.length >= 3;
          });
        });

        const highlightedItem = React.isValidElement(displayItem) ? displayItem : highlightText(String(displayItem), itemMatches);

        return (
          <React.Fragment key={index}>
            {highlightedItem}
            {index < textArray.length - 1 && ', '}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const highlightText = (text, fieldMatches) => {
  if (!text || !fieldMatches || fieldMatches.length === 0) {
    return text;
  }

  const indices = fieldMatches.flatMap((match) =>
    match.indices
      .filter(([start, end]) => {
        const substring = match.value.substring(start, end + 1);
        return text.includes(substring) && substring.length >= 3;
      })
      .map(([start, end]) => [
        text.indexOf(match.value.substring(start, end + 1)),
        text.indexOf(match.value.substring(start, end + 1)) + (end - start),
      ])
  );

  if (indices.length === 0) return text;

  // Merge overlapping indices
  const mergedIndices = indices.reduce((acc, curr) => {
    if (acc.length === 0) return [curr];
    const last = acc[acc.length - 1];
    if (curr[0] <= last[1]) {
      last[1] = Math.max(last[1], curr[1]);
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  let result = [];
  let lastIndex = 0;

  mergedIndices.forEach(([start, end], index) => {
    if (start > lastIndex) {
      result.push(text.substring(lastIndex, start));
    }
    const highlightedText = text.substring(start, end + 1);
    result.push(
      <span key={`highlight-${index}`} className="highlighted-text">
        {highlightedText}
      </span>
    );
    lastIndex = end + 1;
  });

  if (lastIndex < text.length) {
    result.push(text.substring(lastIndex));
  }

  return result.map((item, index) => (typeof item === 'string' ? <React.Fragment key={`text-${index}`}>{item}</React.Fragment> : item));
};

// Add this debounce function at the top of your file, outside of the component
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const Admin = () => {
  const portalConfigs = useContext(PortalContext);
  const [showLabelMaker, setShowLabelMaker] = useState(false);
  const [records, setRecords] = useState({
    pending: [],
    inProcess: [],
    active: [],
    archived: [],
  });
  const [tableFilter, setTableFilter] = useState([]);

  const { getData, data, isLoading, error } = useGet();
  const { keycloak } = useKeycloak();
  const wsRef = useRef(null); // Use a ref to store the WebSocket instance

  const [paginationState, setPaginationState] = useState({
    pendingTable: { pageIndex: 0, pageSize: 10 },
    inProcessTable: { pageIndex: 0, pageSize: 10 },
    activeTable: { pageIndex: 0, pageSize: 10 },
    archivedTable: { pageIndex: 0, pageSize: 10 },
  });

  const [columnVisibilityState, setColumnVisibilityState] = useState({
    pendingTable: {},
    inProcessTable: {},
    activeTable: {},
    archivedTable: {},
  });

  const setPageIndex = (tableType, pageIndex) => {
    setPaginationState((prevState) => ({
      ...prevState,
      [tableType]: { ...prevState[tableType], pageIndex },
    }));
  };

  const setPageSize = (tableType, pageSize) => {
    setPaginationState((prevState) => ({
      ...prevState,
      [tableType]: { pageIndex: 0, pageSize },
    }));
  };

  const setColumnVisibility = useCallback((tableType, newColumnVisibility) => {
    setColumnVisibilityState((prevState) => ({
      ...prevState,
      [tableType]: newColumnVisibility,
    }));
  }, []);

  useEffect(() => {
    getData('/admin/records');
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setRecords({
        pending: data.filter((record) => record.status === 'new'),
        inProcess: data.filter((record) => record.status === 'pending'),
        active: data.filter((record) => record.status === 'active'),
        archived: data.filter((record) => record.status === 'archived'),
      });
    }
  }, [data]);

  const handleWebSocketMessage = useCallback((data) => {
    if (data.collection === 'ClientRecord' && data.change.operationType === 'update') {
      const updatedRecord = data.change.fullDocument;
      const newStatus = updatedRecord.status;

      setRecords((prevRecords) => {
        let oldStatus = null;
        for (const [status, statusRecords] of Object.entries(prevRecords)) {
          if (statusRecords.some((record) => record._id === updatedRecord._id)) {
            oldStatus = status;
            break;
          }
        }
        console.log('oldStatus', oldStatus);
        console.log('newStatus', newStatus);
        const newRecords = { ...prevRecords };

        // Remove the record from its old status array if it exists
        if (oldStatus && oldStatus !== newStatus) {
          newRecords[oldStatus] = newRecords[oldStatus].filter((record) => record._id !== updatedRecord._id);
        }

        // Add or update the record in its new status array
        if (newStatus in newRecords) {
          const index = newRecords[newStatus].findIndex((record) => record._id === updatedRecord._id);
          if (index !== -1) {
            newRecords[newStatus][index] = updatedRecord;
          } else {
            newRecords[newStatus].push(updatedRecord);
          }
        }

        return newRecords;
      });
    }
  }, []); // Remove records from the dependency array

  useEffect(() => {
    // Function to handle incoming WebSocket messages

    // Check if the user is authenticated and has a valid token
    if (keycloak.authenticated && keycloak.token) {
      // Setup the WebSocket connection using the keycloak token and the message handler
      const { connect, disconnect } = setupWebSocket(keycloak, handleWebSocketMessage);
      // Store the connect and disconnect functions in the wsRef ref
      wsRef.current = { connect, disconnect };
      // Establish the WebSocket connection
      wsRef.current.connect();
    }

    // Cleanup function to disconnect the WebSocket when the component is unmounted or dependencies change
    return () => {
      if (wsRef.current) {
        // Disconnect the WebSocket connection
        wsRef.current.disconnect();
      }
    };
  }, [keycloak.authenticated, keycloak.token]); // Dependencies: re-run the effect when authentication status or token changes

  const filterData = useCallback(
    (data) => {
      return data.filter((record) => {
        return tableFilter.every((filter) => {
          const { propertyName, filterValue } = filter;
          // Check primary properties
          if (Array.isArray(record[propertyName])) {
            if (record[propertyName].includes(filterValue)) {
              return true;
            }
          } else if (record[propertyName] === filterValue) {
            return true;
          }

          if (record.hasOwnProperty(propertyName)) {
            if (Array.isArray(record[propertyName])) {
              if (record[propertyName].includes(filterValue)) {
                return true;
              }
            } else if (record[propertyName] === filterValue) {
              return true;
            }
          }

          return false;
        });
      });
    },
    [tableFilter]
  );

  const [keywordSearch, setKeywordSearch] = useState({
    searchTerm: '',
    searchType: 'basic', // 'basic','keyword'
    searchOptions: {
      keys: ['productIdentifier', 'manName', 'aka'], // Add more keys as needed
      threshold: 0.3,
      ignoreLocation: true,
      minMatchCharLength: 2,
      includeMatches: true, // This is important for highlighting
    },
  });

  // Add this new state for the debounced search term
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  // Create a debounced function to update the search term
  const debouncedSetSearchTerm = useCallback(
    debounce((searchTerm, searchType) => {
      setDebouncedSearchTerm(searchTerm);
      setKeywordSearch((prevState) => ({
        ...prevState,
        searchTerm,
        searchType,
        searchOptions: {
          ...prevState.searchOptions,
          keys: searchType === 'basic' ? prevState.searchOptions.keys : [searchType],
        },
      }));
    }, 500),
    []
  );

  // Effect to update search keys when portalConfigs are loaded
  useEffect(() => {
    if (portalConfigs && portalConfigs.portal && portalConfigs.portal.attributeSettings) {
      const additionalSearchKeys = portalConfigs.portal.attributeSettings.filter((setting) => setting.searchInPortal).map((setting) => setting.name);

      setKeywordSearch((prevState) => ({
        ...prevState,
        searchOptions: {
          ...prevState.searchOptions,
          keys: [...prevState.searchOptions.keys, ...additionalSearchKeys],
        },
      }));
    }
  }, [portalConfigs]);

  const filteredRecords = useMemo(() => {
    const filtered = {
      pending: filterData(records.pending),
      inProcess: filterData(records.inProcess),
      active: filterData(records.active),
      archived: filterData(records.archived),
    };

    if (debouncedSearchTerm) {
      const fuse = new Fuse(Object.values(filtered).flat(), keywordSearch.searchOptions);
      const searchResults = fuse.search(debouncedSearchTerm);

      const highlightedResults = searchResults.map((result) => {
        const highlightedItem = { ...result.item };
        result.matches.forEach((match) => {
          const value = highlightedItem[match.key];
          highlightedItem[match.key] = highlightTextOrArray(value, [match]);
        });
        return highlightedItem;
      });

      return {
        pending: highlightedResults.filter((result) => result.status === 'new'),
        inProcess: highlightedResults.filter((result) => result.status === 'pending'),
        active: highlightedResults.filter((result) => result.status === 'active'),
        archived: highlightedResults.filter((result) => result.status === 'archived'),
      };
    }

    return filtered;
  }, [records, filterData, debouncedSearchTerm, keywordSearch.searchOptions]);

  const counts = useMemo(
    () => ({
      pending: filteredRecords.pending.length,
      inProcess: filteredRecords.inProcess.length,
      active: filteredRecords.active.length,
      archived: filteredRecords.archived.length,
    }),
    [filteredRecords]
  );

  const handleRemoveFilter = (indexToRemove) => {
    setTableFilter((prevFilters) => prevFilters.filter((_, index) => index !== indexToRemove));
    // Reset pageIndex to 0 for all table types
    setPaginationState((prevState) => ({
      ...prevState,
      pendingTable: { ...prevState.pendingTable, pageIndex: 0 },
      inProcessTable: { ...prevState.inProcessTable, pageIndex: 0 },
      activeTable: { ...prevState.activeTable, pageIndex: 0 },
      archivedTable: { ...prevState.archivedTable, pageIndex: 0 },
    }));
  };

  const handleAddFilter = (propertyName, filterValue, display) => {
    setTableFilter((prevFilters) => {
      // Check if the filter already exists
      const existingFilterIndex = prevFilters.findIndex((filter) => filter.propertyName === propertyName && filter.filterValue === filterValue);

      if (existingFilterIndex !== -1) {
        // If the filter already exists, don't add it again
        return prevFilters;
      }

      // Add the new filter with the optional display property
      return [...prevFilters, { propertyName, filterValue, display }];
    });
    // Reset pageIndex to 0 for all table types
    setPaginationState((prevState) => ({
      ...prevState,
      pendingTable: { ...prevState.pendingTable, pageIndex: 0 },
      inProcessTable: { ...prevState.inProcessTable, pageIndex: 0 },
      activeTable: { ...prevState.activeTable, pageIndex: 0 },
      archivedTable: { ...prevState.archivedTable, pageIndex: 0 },
    }));
  };

  const handleSearchChange = useCallback(
    (searchTerm, searchType) => {
      // Immediately update the UI
      setKeywordSearch((prevState) => ({
        ...prevState,
        searchTerm,
        searchType,
      }));

      // Debounce the actual search
      debouncedSetSearchTerm(searchTerm, searchType);

      // Reset pageIndex to 0 for all table types
      setPaginationState((prevState) => ({
        ...prevState,
        pendingTable: { ...prevState.pendingTable, pageIndex: 0 },
        inProcessTable: { ...prevState.inProcessTable, pageIndex: 0 },
        activeTable: { ...prevState.activeTable, pageIndex: 0 },
        archivedTable: { ...prevState.archivedTable, pageIndex: 0 },
      }));
    },
    [debouncedSetSearchTerm]
  );

  const ViewRecord = () => {
    const { recordId } = useParams();

    const record = useMemo(() => {
      const allRecords = [...records.active, ...records.archived, ...records.inProcess, ...records.pending];

      return allRecords.find((r) => String(r.recordId) === String(recordId));
    }, [records, recordId]);

    if (!record) {
      return <div>Record not found for ID: {recordId}</div>;
    }

    return (
      <PhotoSwipe
        pdfRendered={record}
        setPdfRendered={() => {}}
        subdomain={portalConfigs.clientSubdomain}
        isMobile={true}
        userSettings={{ showPhotos: true }}
        onClose={() => window.history.back()}
      />
    );
  };

  // Add these new states for column visibility and leaf columns
  const [leafColumnsState, setLeafColumnsState] = useState({
    pendingTable: null,
    inProcessTable: null,
    activeTable: null,
    archivedTable: null,
  });

  const initializeLeafColumns = useCallback((tableType, columns) => {
    setLeafColumnsState((prevState) => {
      if (prevState[tableType] === null) {
        return {
          ...prevState,
          [tableType]: columns,
        };
      }
      return prevState;
    });
  }, []);

  return (
    <Container fluid className="p-0" style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <NavigationBar />
      <Routes>
        <Route path="view/:recordId" element={<ViewRecord />} />
        <Route
          path="*"
          element={
            <>
              <LifecycleBar
                counts={counts}
                onSearchChange={handleSearchChange}
                searchTerm={keywordSearch.searchTerm}
                searchType={keywordSearch.searchType}
              />

              <div id="tableArea" className="main-content mt-2 p-3" style={{ overflowY: 'auto' }}>
                <TableFilters filters={tableFilter} onRemoveFilter={handleRemoveFilter} />

                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error: {error.message}</p>
                ) : (
                  <Routes>
                    {['pending', 'in-process', 'active', 'archived'].map((status) => {
                      const tableType = `${status.replace('-', '')}Table`;
                      return (
                        <Route
                          key={status}
                          path={status}
                          element={
                            <LifecycleTable
                              data={filteredRecords[status.replace('-', '')]}
                              tableType={tableType}
                              pageIndex={paginationState[tableType]?.pageIndex || 0}
                              pageSize={paginationState[tableType]?.pageSize || 10}
                              setPageIndex={(pageIndex) => setPageIndex(tableType, pageIndex)}
                              setPageSize={(pageSize) => setPageSize(tableType, pageSize)}
                              portalConfigs={portalConfigs}
                              onAddFilter={handleAddFilter}
                              columnVisibility={columnVisibilityState[tableType]}
                              setColumnVisibility={(newColumnVisibility) => setColumnVisibility(tableType, newColumnVisibility)}
                            />
                          }
                        />
                      );
                    })}
                    <Route path="*" element={<Navigate to="active" replace />} />
                  </Routes>
                )}
              </div>

              {/* Label Maker Button */}
              <Button
                variant="primary"
                className="label-maker-btn"
                onClick={() => setShowLabelMaker(!showLabelMaker)}
                style={{
                  position: 'fixed',
                  right: 0,
                  top: '50%',
                  transform: 'rotate(-90deg) translateY(-50%)',
                  transformOrigin: 'right bottom',
                }}
              >
                Label Maker
              </Button>

              {/* Label Maker Panel */}
              {showLabelMaker && (
                <div
                  className="label-maker-panel"
                  style={{
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '300px',
                    backgroundColor: '#f8f9fa',
                    padding: '20px',
                    boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
                  }}
                >
                  <h4>Label Maker</h4>
                  {/* Add label maker content here */}
                </div>
              )}
            </>
          }
        />
      </Routes>
    </Container>
  );
};

export default Admin;
