import React from 'react';
import ghs01 from 'assets/icons/ghs01.svg';
import ghs02 from 'assets/icons/ghs02.svg';
import ghs03 from 'assets/icons/ghs03.svg';
import ghs04 from 'assets/icons/ghs04.svg';
import ghs05 from 'assets/icons/ghs05.svg';
import ghs06 from 'assets/icons/ghs06.svg';
import ghs07 from 'assets/icons/ghs07.svg';
import ghs08 from 'assets/icons/ghs08.svg';
import ghs09 from 'assets/icons/ghs09.svg';
import pictogramBorder from 'assets/icons/pictogram-border.svg';

const Pictogram = ({ ghs }) => {
  const titles = {
    ghs01: 'Explosive',
    ghs02: 'Flammable',
    ghs03: 'Oxidizing',
    ghs04: 'Compressed Gas',
    ghs05: 'Corrosive',
    ghs06: 'Toxic',
    ghs07: 'Health Hazard',
    ghs08: 'Serious Health Hazard',
    ghs09: 'Hazardous to the environment',
  };

  const lowerCase = ghs.toLowerCase();
  const iconSrc = {
    ghs01,
    ghs02,
    ghs03,
    ghs04,
    ghs05,
    ghs06,
    ghs07,
    ghs08,
    ghs09,
  }[lowerCase];

  return (
    <span className="pictogram" style={{ position: 'relative', display: 'inline-block', width: '3em', height: '3em' }}>
      <img src={pictogramBorder} alt="Pictogram border" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
      <img
        src={iconSrc}
        alt={titles[lowerCase]}
        title={titles[lowerCase]}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      />
    </span>
  );
};

export default Pictogram;
