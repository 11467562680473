import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import Pictogram from './sub-components/Pictogram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@awesome.me/kit-75da6535ae/icons/classic/solid';

const TableFilters = ({ filters, onRemoveFilter }) => {
  return (
    <div className="d-flex flex-wrap gap-2 mb-3">
      {filters.map((filter, index) => (
        <Badge key={index} bg="secondary" className="d-flex align-items-center">
          <span className="me-2">{filter.display || filter.filterValue}</span>
          <Button variant="link" className="p-0 text-light" onClick={() => onRemoveFilter(index)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Badge>
      ))}
    </div>
  );
};

export default TableFilters;
