import React, { useState } from 'react';

const HelpText = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {text && (
        <>
          <span title={text} className="help-text-icon" onClick={toggleVisibility} style={{ marginLeft: '5px', cursor: 'pointer' }}>
            <i className={`fa-regular fa-circle-question ${isVisible ? 'text-primary' : ''}`}></i>
          </span>
          <small className="form-text text-muted" style={{ fontWeight: 'normal', display: isVisible ? 'block' : 'none' }}>
            {text}
          </small>
        </>
      )}
    </>
  );
};

export default HelpText;
