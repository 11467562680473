/**
 * ModalContext
 *
 * This context provides a way to manage multiple modals within an application. It allows for showing, hiding, updating, and bringing modals to the front.
 *
 * Functions provided by the context:
 * - showModal(modalProps): Displays a modal with the given properties.
 * - hideModal(id): Hides the modal with the specified ID.
 * - updateModal(id, newProps): Updates the properties of the modal with the specified ID.
 * - bringToFront(id): Brings the modal with the specified ID to the front.
 *
 * Usage:
 *
 * ```jsx
 * import React from 'react';
 * import { ModalProvider, useModal } from './context/ModalContext';
 * import PowerModal from './components/admin/sub-components/PowerModal';
 *
 * function App() {
 *   const { showModal, hideModal } = useModal();
 *
 *   const handleShowModal = () => {
 *     showModal({
 *       id: 'exampleModal',
 *       title: 'Example Modal',
 *       children: <p>This is an example modal content.</p>,
 *       size: 'lg',
 *       draggable: true,
 *       resizable: true,
 *       overlay: true,
 *     });
 *   };
 *
 *   return (
 *     <ModalProvider>
 *       <button onClick={handleShowModal}>Show Modal</button>
 *     </ModalProvider>
 *   );
 * }
 * ```
 *
 * This context leverages `useState`, `useContext`, `useCallback`, and `useMemo` hooks from React to manage the state and behavior of modals.
 *
 * Ensure you have the `PowerModal` component properly set up to work with this context.
 */

import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';
import PowerModal from '../components/admin/sub-components/PowerModal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  const showModal = useCallback((modalProps) => {
    setModals((prevModals) => {
      const existingModalIndex = prevModals.findIndex((modal) => modal.id === modalProps.id);
      const maxZIndex = prevModals.length > 0 ? Math.max(...prevModals.map((modal) => modal.zIndex)) : 0;
      const newModal = { ...modalProps, show: true, zIndex: maxZIndex + 1, modalId: `modal-${Date.now()}` };

      if (existingModalIndex !== -1) {
        // If modal with this ID already exists, update it
        const updatedModals = [...prevModals];
        updatedModals[existingModalIndex] = newModal;
        return updatedModals;
      } else {
        // If it's a new modal, add it to the array
        return [...prevModals, newModal];
      }
    });
  }, []);

  const hideModal = useCallback((id) => {
    setModals((prevModals) => prevModals.filter((modal) => modal.id !== id));
  }, []);

  const updateModal = useCallback((id, newProps) => {
    setModals((prevModals) => prevModals.map((modal) => (modal.id === id ? { ...modal, ...newProps } : modal)));
  }, []);

  const bringToFront = useCallback((id) => {
    setModals((prevModals) => {
      const maxZIndex = prevModals.length > 0 ? Math.max(...prevModals.map((modal) => modal.zIndex)) : 0;
      return prevModals.map((modal) => (modal.id === id ? { ...modal, zIndex: maxZIndex + 1 } : modal));
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
      updateModal,
      bringToFront,
    }),
    [showModal, hideModal, updateModal, bringToFront]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modals.map((modal) => (
        <PowerModal key={modal.id} {...modal} onHide={() => hideModal(modal.id)} onClick={() => bringToFront(modal.id)} />
      ))}
    </ModalContext.Provider>
  );
};
