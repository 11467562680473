import './App.css';
import { PortalProvider } from './context/Portal';
import kc from './keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import SplashScreen from './components/portal/Splashscreen';
import { useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
//pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

function App() {
  const [keycloakReady, setKeycloakReady] = useState(false);

  //appends the fontawesome kit
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://kit.fontawesome.com/75da6535ae.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onKeycloakEvent = (event, error) => {
    console.log('onKeycloakEvent', event, error);
    console.log(`Keycloak Event ${event}`);
    if (event && event === 'onReady') {
      setKeycloakReady(true);
    }
  };

  const onKeycloakTokens = (tokens) => {
    console.log('onKeycloakTokens', tokens);
  };

  console.log('authClient is set', kc);
  return (
    <ReactKeycloakProvider
      authClient={kc}
      initOptions={{ pkceMethod: 'S256', checkLoginIframe: false }}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <SplashScreen keycloakReady={keycloakReady}>
        <PortalProvider />
      </SplashScreen>
    </ReactKeycloakProvider>
  );
}

export default App;
