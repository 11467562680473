import useRequest from './useRequest';

export const usePatch = () => {
  const { sendRequest, response, isLoading, error } = useRequest('patch');

  const patchData = (path, data, options = {}) => {
    return sendRequest(path, data, options);
  };

  return { patchData, response, isLoading, error };
};
