import React, { useState, useEffect, useMemo } from 'react';
import PaginationBar from './Pagination-Bar';
//import PaginatedSDSList from './Paginated-SDS-List';
import ListGroup from 'react-bootstrap/ListGroup';

import PaginatedSDSItem from './Paginated-SDS-Item';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PaginatedSDSDocuments = ({ filteredSDSDocuments, matchData, totalResults, setSelectedLocation, userSettings, searchTerm, portalConfigs }) => {
  const navigate = useNavigate();
  const pageLength = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);

  // Use useMemo to sort documents only when filteredSDSDocuments changes
  const sortedDocuments = useMemo(() => {
    return filteredSDSDocuments ? [...filteredSDSDocuments].sort((a, b) => (a.score || 0) - (b.score || 0)) : [];
  }, [filteredSDSDocuments]);

  useEffect(() => {
    if (sortedDocuments.length > 0) {
      setTotalDocuments(sortedDocuments.length);
      setTotalPages(Math.ceil(sortedDocuments.length / pageLength));
      setCurrentPage(1);
    }
  }, [sortedDocuments, pageLength]);

  useEffect(() => {
    const start = (currentPage - 1) * pageLength;
    const end = start + pageLength;
    setCurrentDocuments(sortedDocuments.slice(start, end));
  }, [currentPage, sortedDocuments, pageLength]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const setLocationHash = (locationID) => {
    navigate(`/loc/${locationID}`);
  };

  return (
    // Your JSX code goes here
    <div className="px-lg-3">
      {totalPages > 1 && (
        <PaginationBar
          hideMobile="true"
          currentPage={currentPage}
          totalPages={totalPages}
          totalDocuments={totalDocuments}
          handlePageChange={handlePageChange}
        />
      )}
      <div className="mb-5 mb-lg-3">
        <ListGroup>
          {currentDocuments.map((document) => (
            <PaginatedSDSItem
              key={document.recordId}
              sdsDocument={document}
              userSettings={userSettings}
              matches={matchData[document.recordId] || []}
              searchTerm={searchTerm}
              portalConfigs={portalConfigs}
            />
          ))}
        </ListGroup>
      </div>
      {currentDocuments.length === 0 && totalResults && (
        <div className="d-grid gap-2">
          <Button variant="warning" size="lg" onClick={() => setLocationHash(0)}>
            View the <strong>{totalResults}</strong> result{totalResults === 1 ? '' : 's'} outside of the selected location
          </Button>
        </div>
      )}
      {totalPages > 1 && (
        <PaginationBar currentPage={currentPage} totalPages={totalPages} totalDocuments={totalDocuments} handlePageChange={handlePageChange} />
      )}
    </div>
  );
};

export default PaginatedSDSDocuments;
