import React, { useState, useEffect } from 'react';
import { Button, Form, Collapse } from 'react-bootstrap';
import { StringEdit } from 'components/custom-attributes/String';
import { NumberEdit } from 'components/custom-attributes/Number';
import { MultiSelectEdit } from 'components/custom-attributes/Multi-Select';
import { TextAreaEdit } from 'components/custom-attributes/TextArea';
import { usePost } from '../../../hooks/usePost';

const EmployeeRequest = ({ portalConfigs }) => {
  const defaultConfig = {
    enabled: true,
    formDefinition: [
      {
        label: 'Product Name',
        required: true,
        name: 'productIdentifier',
        type: 'string',
        editClasses: {
          component: '',
          label: '',
          input: '',
        },
      },
      {
        label: 'Product Number',
        required: false,
        name: 'aka',
        type: 'string',
        editClasses: {
          component: '',
          label: '',
          input: '',
        },
      },
      {
        label: 'Product Manufacturer',
        required: true,
        name: 'manName',
        type: 'string',
        editClasses: {
          component: '',
          label: '',
          input: '',
        },
        helpText: 'Manufacturer Names are typically found on the back of the product.',
      },
      {
        label: 'Product UPC Code',
        required: false,
        name: 'upc',
        type: 'string',
        editClasses: {
          component: '',
          label: '',
          input: '',
        },
      },
      {
        label: 'Details',
        required: false,
        name: 'requestDetails',
        type: 'textArea',
      },
      {
        label: 'Requestor Name',
        required: false,
        name: 'requestorName',
        type: 'string',
      },
      {
        label: 'Phone Number',
        required: false,
        name: 'requestorPhone',
        type: 'string',
      },
    ],
  };

  //use the defaultConfig as the base setup for the portalConfig.portal.employeeRequests object
  const employeeRequests = { ...defaultConfig, ...portalConfigs.portal.employeeRequests };

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [buttonText, setButtonText] = useState('Request Missing SDS');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { postData, isLoading, error } = usePost();

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const isValid = employeeRequests.formDefinition.every((field) => {
      if (field.required) {
        return formData[field.name] && (typeof formData[field.name] !== 'string' || formData[field.name].trim() !== '');
      }
      return true;
    });
    setIsFormValid(isValid);
  }, [formData, employeeRequests.formDefinition]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      alert('Please fill in all required fields.');
      return;
    }
    try {
      await postData('/portal/request', formData);
      setFormData({}); // Clear the form after successful submission
      setOpen(false); // Collapse the form
      setIsSubmitted(true);
      setButtonText('Your Request has been Submitted');

      // Reset button text after 5 seconds
      setTimeout(() => {
        setButtonText('Request Missing SDS');
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting request:', error);
      alert('Failed to submit request');
    }
  };

  const renderField = (field) => {
    const defaultAttribute = portalConfigs.portal.attributeSettings.find((attr) => attr.name === field.name); //pulls data from the attributeSettings array in the portalConfigs
    //overlay the data found in the employeeRequests.formDefinition array
    const mergedAttribute = { ...field, ...defaultAttribute };
    const fieldValue = formData[field.name] || ''; // Get the current value from formData
    const mergedAttributeWithValue = { ...mergedAttribute, value: fieldValue }; // Add the current value to the field object

    switch (mergedAttributeWithValue.type) {
      case 'string':
        return <StringEdit key={mergedAttributeWithValue.name} attribute={mergedAttributeWithValue} onChange={handleChange} />;
      case 'number':
        return <NumberEdit key={mergedAttributeWithValue.name} attribute={mergedAttributeWithValue} onChange={handleChange} />;
      case 'multi-select':
        return <MultiSelectEdit key={mergedAttributeWithValue.name} attribute={mergedAttributeWithValue} onChange={handleChange} />;
      case 'textArea':
        return <TextAreaEdit key={mergedAttributeWithValue.name} attribute={mergedAttributeWithValue} onChange={handleChange} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Button
        onClick={() => !isSubmitted && setOpen(!open)}
        aria-controls="employee-request-form"
        aria-expanded={open}
        size="lg"
        variant={isSubmitted ? 'info' : 'success'}
        disabled={isSubmitted}
      >
        {buttonText}
      </Button>
      <Collapse in={open}>
        <div id="employee-request-form" className="bg-white mt-2">
          <Form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap flex-md-row justify-content-center justify-content-md-between">
            {employeeRequests.formDefinition.map((field) => renderField(field))}
            <div className="d-flex align-items-end p-2">
              <Button type="submit" disabled={!isFormValid || isLoading} className="flex-fill">
                {isLoading ? 'Submitting...' : 'Submit Request'}
              </Button>
            </div>
          </Form>
        </div>
      </Collapse>
      {error && <div className="text-danger mt-2">Error: {error.message}</div>}
    </>
  );
};

export default EmployeeRequest;
