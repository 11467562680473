import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears, faTimes, faExclamationTriangle, faBinoculars, faArchive } from '@fortawesome/pro-solid-svg-icons';
import { faCircleChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { usePatch } from '../../../../hooks/usePatch';

const requestChemical = (record) => {
  console.log('Request SDS:', record);
  //update the record status to inProgress
  //send to Data Sheet Solutions
  const updateRecordStatus = async (recordId, status) => {
    try {
      const response = await fetch(`/api/admin/records/${recordId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });

      if (!response.ok) {
        throw new Error('Failed to update record status');
      }

      const updatedRecord = await response.json();
      console.log('Record updated:', updatedRecord);
    } catch (error) {
      console.error('Error updating record status:', error);
    }
  };

  updateRecordStatus(record._id, 'inProgress');
};

const Actions = ({ record }) => {
  const { patchData, isLoading, error } = usePatch();

  const updateRecordStatus = async (record, newStatus) => {
    try {
      console.log('Update Record Status:', record, newStatus);
      const recordId = record._id;
      const operations = [
        {
          action: 'set',
          field: 'status',
          value: newStatus,
        },
      ];
      // Use the patchData function returned by usePatch
      const response = await patchData(`/admin/records/${recordId}`, { operations });
      console.log('Record updated:', response);
    } catch (error) {
      console.error('Error updating record:', error);
    }
  };

  const navigate = useNavigate();

  const viewDocument = (record) => {
    console.log('View Document:', record);
    navigate(`/admin/view/${record.recordId}`);
  };

  //available actions will be based on the status of the record, account features, and the users permissions
  const availableActions = [];
  const dropDownActions = []; //this is going to hold the dropdown-items for the dropdown button

  switch (record.status) {
    case 'new':
      availableActions.push(
        <button key="requestChemical" onClick={() => requestChemical(record)} style={{ border: 'none', background: 'none' }}>
          <FontAwesomeIcon icon={faGears} title="Send to Data Sheet Solutions" transform="grow-8" />
        </button>
      );
      dropDownActions.push(
        <Dropdown.Item key="requestChemical" onClick={() => requestChemical(record)}>
          Request SDS
          <FontAwesomeIcon icon={faGears} title="Send to Data Sheet Solutions" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="sendArchive" onClick={() => updateRecordStatus(record, 'archived')}>
          Archive Request
          <FontAwesomeIcon icon={faArchive} title="Archive" className="ms-2" />
        </Dropdown.Item>
      );
      break;
    case 'inProgress':
      availableActions.push(<FontAwesomeIcon icon={faTimes} title="Rejected" />);
      break;
    case 'active':
      availableActions.push(
        <button key="viewDocument" onClick={() => viewDocument(record)} style={{ border: 'none', background: 'none' }}>
          <FontAwesomeIcon icon={faBinoculars} title="View Document" transform="grow-8" />
        </button>
      );
      dropDownActions.push(
        <Dropdown.Item key="viewDocument" onClick={() => viewDocument(record)}>
          View Document
          <FontAwesomeIcon icon={faBinoculars} title="View Document" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="sendPending" onClick={() => updateRecordStatus(record, 'new')}>
          Send Back to Pending
          <FontAwesomeIcon icon={faGears} title="Send Back to Pending" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="sendArchive" onClick={() => updateRecordStatus(record, 'archived')}>
          Archive Request
          <FontAwesomeIcon icon={faArchive} title="Archive" className="ms-2" />
        </Dropdown.Item>
      );
      break;
    case 'archived':
      availableActions.push(
        <button key="restoreRecord" onClick={() => updateRecordStatus(record, 'active')} style={{ border: 'none', background: 'none' }}>
          <FontAwesomeIcon icon={faArchive} title="Restore Record" transform="grow-8" />
        </button>
      );
      dropDownActions.push(
        <Dropdown.Item key="restoreRecord" onClick={() => updateRecordStatus(record, 'active')}>
          Restore Record
          <FontAwesomeIcon icon={faArchive} title="Restore Record" className="ms-2" />
        </Dropdown.Item>
      );
      break;
    default:
      availableActions.push(<FontAwesomeIcon icon={faExclamationTriangle} title="Unknown Status" />);
      break;
  }
  if (dropDownActions.length > 0) {
    availableActions.push(
      <Dropdown key="dropdown">
        <Dropdown.Toggle variant="secondary" id="dropdownMenuButton" className="noCaret" as="div">
          <FontAwesomeIcon icon={faCircleChevronDown} title="More Actions" transform="grow-8" />
        </Dropdown.Toggle>
        <Dropdown.Menu>{dropDownActions}</Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <div className="actions" style={{ display: 'flex', gap: '10px' }}>
      {availableActions}
    </div>
  );
};

export default Actions;
