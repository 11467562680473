import { useState } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

export const usePost = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { keycloak } = useKeycloak();

  const apiURLPrefix = process.env.NODE_ENV !== 'production' ? '' : '/api';
  const subdomain = window.location.hostname.split('.')[0];

  const postData = async (path, data, options = {}) => {
    setIsLoading(true);
    setError(null);
    try {
      const headers = {
        subdomain,
        'Content-Type': 'application/json',
        ...(keycloak.authenticated && { Authorization: `Bearer ${keycloak.token}` }),
      };

      const response = await axios.post(`${apiURLPrefix}${path}`, data, { ...options, headers });
      setResponse(response.data);
      return response;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { postData, response, isLoading, error };
};
