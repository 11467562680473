/**
 * PowerModal Component
 *
 * This component is a customizable modal dialog built using `react-bootstrap` and `react-draggable`.
 * It provides features such as draggable and resizable modals, and allows for custom content and styling.
 *
 * Props:
 * - show (bool): Controls the visibility of the modal.
 * - onHide (func): Function to call when the modal is requested to be closed.
 * - title (string): Title text to display in the modal header.
 * - footerContent (node): Content to display in the modal footer.
 * - size (string): Size of the modal ('sm', 'lg', 'xl').
 * - children (node): Content to display in the modal body.
 * - draggable (bool): Enables dragging of the modal.
 * - resizable (bool): Enables resizing of the modal.
 * - overlay (bool): Controls the display of the overlay backdrop.
 * - onClick (func): Function to call when the modal is clicked.
 * - zIndex (number): zIndex value to apply to the modal for stacking order.
 *
 * Usage:
 *
 * ```jsx
 * import PowerModal from './PowerModal';
 *
 * function App() {
 *   const [showModal, setShowModal] = useState(false);
 *
 *   const handleShow = () => setShowModal(true);
 *   const handleClose = () => setShowModal(false);
 *
 *   return (
 *     <div>
 *       <button onClick={handleShow}>Show Modal</button>
 *       <PowerModal
 *         show={showModal}
 *         onHide={handleClose}
 *         title="My Modal Title"
 *         footerContent={<button onClick={handleClose}>Close</button>}
 *         size="lg"
 *         draggable
 *         resizable
 *         overlay
 *         onClick={() => console.log('Modal clicked')}
 *         zIndex={1050}
 *       >
 *         <p>This is the modal content.</p>
 *       </PowerModal>
 *     </div>
 *   );
 * }
 * ```
 *
 * This component leverages `react-bootstrap` for the modal structure and `react-draggable` for the draggable functionality.
 * Ensure you have both `react-bootstrap` and `react-draggable` installed in your project.
 *
 * Installation:
 *
 * ```bash
 * npm install react-bootstrap react-draggable
 * ```
 *
 * For more information on `react-bootstrap` modals, refer to the [react-bootstrap documentation](https://react-bootstrap.github.io/components/modal/).
 * For draggable functionality, refer to the [react-draggable documentation](https://github.com/react-grid-layout/react-draggable).
 */

import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Draggable from 'react-draggable';
import './PowerModal.css';

// Import the image
import datasheetSolutionsIcon from '../../../assets/images/datasheetsolutions_small.png';

const DraggableModalDialog = ({ children, ...props }) => {
  const nodeRef = useRef(null);
  const [position, setPosition] = useState(null);
  const hasBeenCentered = useRef(false);

  const centerModal = useCallback(() => {
    if (hasBeenCentered.current) return;

    const modalDialog = nodeRef.current;
    if (modalDialog) {
      const modalWidth = modalDialog.offsetWidth;
      const modalHeight = modalDialog.offsetHeight;
      const centerX = (window.innerWidth - modalWidth) / 2;
      const centerY = (window.innerHeight - modalHeight) / 2;

      setPosition({ x: centerX, y: centerY });
      hasBeenCentered.current = true;
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(centerModal, 0);
    return () => clearTimeout(timer);
  }, [centerModal]);

  const { contentClassName, ...restProps } = props;

  return (
    <Draggable handle=".modal-header" nodeRef={nodeRef} position={position} onStop={(e, data) => setPosition({ x: data.x, y: data.y })}>
      <div ref={nodeRef} className={`modal-dialog ${contentClassName || ''}`} {...restProps} style={{ zIndex: props.zIndex }} onClick={props.onClick}>
        {children}
      </div>
    </Draggable>
  );
};

const PowerModal = ({ show, onHide, title, footerContent, size, children, draggable, resizable, overlay, onClick, zIndex }) => {
  const modalContent = useMemo(
    () => (
      <Modal
        show={show}
        onHide={onHide}
        size={size}
        backdrop={overlay ? true : false}
        dialogAs={draggable ? DraggableModalDialog : undefined}
        dialogClassName={`clickable-modal ${resizable ? 'resizable-modal' : ''} ${size} ${draggable ? 'draggable-modal' : ''}`}
        onClick={onClick}
        style={{ zIndex }}
      >
        <Modal.Header closeButton className={draggable ? 'draggable-header' : ''}>
          <Modal.Title as="h5">
            <img src={datasheetSolutionsIcon} alt="icon" style={{ display: 'inline-block', marginRight: '8px', width: '30px', height: '30px' }} />
            <span>{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {footerContent && <Modal.Footer>{footerContent}</Modal.Footer>}
      </Modal>
    ),
    [show, onHide, title, footerContent, size, children, draggable, resizable, overlay, onClick, zIndex]
  );

  return modalContent;
};

PowerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  footerContent: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  children: PropTypes.node.isRequired,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  overlay: PropTypes.bool,
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
};

PowerModal.defaultProps = {
  title: '',
  footerContent: null,
  size: 'sm',
  draggable: true,
  resizable: false,
  overlay: true,
  onClick: () => {},
  zIndex: 1,
};

export default PowerModal;
