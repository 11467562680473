import { useEffect, useState, useContext, useRef } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { useGet } from 'hooks/useGet';
import { PortalContext } from 'context/Portal';
import { useNavigate } from 'react-router-dom';

const PDFJS = require('pdfjs-dist/webpack');

const Photoswipe = ({ pdfRendered, setPdfRendered, subdomain, isMobile, userSettings, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [documentData, setDocumentData] = useState(false);
  const [previewURL, setPreviewURL] = useState(false);
  const previewRef = useRef(previewURL);
  const [totalPages, setTotalPages] = useState(0);
  const documentRequest = useGet();
  const portalConfigs = useContext(PortalContext);
  const navigate = useNavigate();

  //create a function that takes one argument, the dataURL, creates a window opening that url and the calling the print function
  const printDocument = (dataURL) => {
    const printWindow = window.open(dataURL, '_blank');
    printWindow.onload = () => {
      printWindow.print();
    };
    return false;
  };

  useEffect(() => {
    if (documentRequest.data && documentRequest.isLoading === false) {
      setDocumentData(documentRequest.data);
    }
  }, [documentRequest.data]);

  useEffect(() => {
    previewRef.current = previewURL;
  }, [previewURL]);

  useEffect(() => {
    //if isOpen is true wait 1 second and the update the download-button element with the previewRef
    if (isOpen) {
      setTimeout(() => {
        let newfilename = pdfRendered.name;
        if (newfilename === undefined) {
          newfilename = pdfRendered.productIdentifier;
        }
        const downloadButton = document.getElementById('download-button');
        downloadButton.href = previewRef.current;
        downloadButton.download = newfilename.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.pdf';
      }, 1000);
    }
  }, [isOpen]);

  //when the component loads, get the document from remote server or local storage
  useEffect(() => {
    documentRequest.getDocument(pdfRendered);
    document.body.style.overflow = 'hidden';
    if (!portalConfigs.lightbox) {
      const options = {
        dataSource: [],
        pswpModule: () => import('photoswipe'),
        index: 0,
        bgOpacity: 0.7,
        showHideOpacity: true,
        closeOnScroll: false,
        hideAnimationDuration: 0,
        showAnimationDuration: 0,
        preload: [1, 1],
      };
      const lightbox = new PhotoSwipeLightbox(options);
      lightbox.on('close', () => {
        handleClose();
      });
      //setup addition UI elements
      lightbox.on('uiRegister', function () {
        lightbox.pswp.ui.registerElement({
          name: 'download-button',
          order: 8,
          isButton: true,
          tagName: 'a',

          // SVG with outline
          html: {
            isCustomSVG: true,
            inner: '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
            outlineID: 'pswp__icn-download',
          },
          onInit: (el, pswp) => {
            el.setAttribute('download', '');
            el.setAttribute('target', '_blank');
            el.setAttribute('rel', 'noopener');
            el.href = 'none';
            el.download = 'none.pdf';
            el.id = 'download-button';
          },
        });
      });
      lightbox.init();
      portalConfigs.setLightbox(lightbox);
    }

    return () => {
      document.body.style.overflow = 'auto';
      URL.revokeObjectURL(previewURL);
      previewRef.current = false;
      setPreviewURL(false);
      setPdfRendered(false);
      if (document.querySelector('.pswp')) {
        document.querySelector('.pswp').style.display = 'none';
      }
      setIsOpen(false);
    };
  }, []);

  //useEffect to monitor the items array and update the lightbox dataSource
  useEffect(() => {
    if (portalConfigs.lightbox) {
      portalConfigs.lightbox.options.dataSource = items;
    }
  }, [items]);

  const convertPdfToImages = async (documentData, lightbox) => {
    const images = [];
    const pdf = await PDFJS.getDocument({ data: documentData }).promise;
    setTotalPages(pdf.numPages);
    const canvas = document.createElement('canvas');
    if (document.querySelector('.pswp')) {
      document.querySelector('.pswp').style.display = 'block';
    }
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 2 });
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      let itemObj = {
        src: canvas.toDataURL(),
        w: viewport.width,
        h: viewport.height,
      };
      setItems((items) => [...items, itemObj]);
    }
    return images;
  };

  useEffect(() => {
    if (documentData !== false) {
      //create a previewURL for the pdf
      setPreviewURL(URL.createObjectURL(new Blob([documentData], { type: 'application/pdf' })));
      if (isMobile || userSettings.internalViewer) {
        //showing in internal viewer, begin photoswipe required tasks
        convertPdfToImages(documentData, portalConfigs.lightbox).then((images) => {
          portalConfigs.lightbox.loadAndOpen(0);
          setIsOpen(true);
        });
      }
    }
  }, [documentData]);

  const handleClose = () => {
    setIsOpen(false);
    setPdfRendered(false);
    if (onClose) {
      onClose();
    } else {
      navigate('/');
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        backgroundColor: '#000', // Change background color to transparent
        zIndex: 8888,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {items.length <= totalPages &&
        (isMobile || userSettings.internalViewer) && ( //showing loading spinner with page counts because we are working with the internal viewer
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 className="text-light">
              <i className="fa-duotone fa-loader fa-spin-pulse fa-2xl" />
            </h1>
            <br />
            <p style={{ color: '#fff' }}>
              Loading {items.length || 0} of {totalPages || 0}
            </p>
          </div>
        )}
      {!previewURL &&
        !isMobile &&
        !userSettings.internalViewer && ( //just showing the plan loading spinner because we are loading the PDF directly.
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 className="text-light">
              <i className="fa-duotone fa-loader fa-spin-pulse fa-2xl" />
            </h1>
            <br />
            <p style={{ color: '#fff' }}>Loading Document</p>
          </div>
        )}
      {previewURL && !isMobile && !userSettings.internalViewer && (
        //showing the PDF in an iframe because we are not using the internal viewer
        <iframe
          src={previewURL}
          title="PDF Document"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      )}
    </div>
  );
};

export default Photoswipe;
