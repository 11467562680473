export const setupWebSocket = (keycloak, onMessage) => {
  let ws = null;

  const connect = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log('WebSocket connection already open');
      return;
    }

    const subdomain = window.location.hostname.split('.')[0];
    const token = keycloak.token;

    if (!token) {
      console.log('Keycloak token not available yet');
      return;
    }

    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsUrl =
      process.env.NODE_ENV === 'development'
        ? `${protocol}//${subdomain}.localhost:3499/admin/updates?token=${token}`
        : `${protocol}//${subdomain}.sds.zone/api/admin/updates?token=${token}`;
    console.log('Connecting to WebSocket');
    ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log('WebSocket connection opened');
    };

    ws.onmessage = (event) => {
      const change = JSON.parse(event.data);
      console.log('Change received:', change);
      onMessage(change);
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed', event);
      ws = null;
      // Attempt to reconnect after a delay
      setTimeout(connect, 5000);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error', error);
    };
  };

  const disconnect = () => {
    if (ws) {
      ws.close();
      ws = null;
    }
  };

  return { connect, disconnect };
};
