import React from 'react';
import { flexRender } from '@tanstack/react-table';

const TableBody = ({ rows }) => (
  <tbody>
    {rows.map((row) => (
      <tr key={row.id}>
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id} className="align-middle" style={{ textAlign: cell.column.columnDef.textAlign || 'left' }}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export default TableBody;
