import React from 'react';
import { Badge, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import './LifecycleBar.css';

const LifecycleBar = ({ counts, onSearchChange, searchTerm, searchType }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleDivClick = (path) => {
    navigate(`/admin/${path}`);
  };

  const getClassName = (path) => {
    return location.pathname.includes(path) ? 'lifecycle-item currentTab' : 'lifecycle-item';
  };

  return (
    <div className="lifecycle-bar mt-5 pt-3 p-2 bg-light">
      <div
        className={`${getClassName('pending')} mr-2 pendingTab`}
        onClick={() => handleDivClick('pending')}
        style={{ cursor: 'pointer', display: 'inline-block', zIndex: 10 }}
      >
        <i className="fa-thin fa-user-magnifying-glass me-2" />
        Pending Your Review{' '}
        <Badge bg="light" text="dark">
          {counts.pending}
        </Badge>
      </div>
      <div
        className={`${getClassName('in-process')} mr-2 inProcessTab`}
        onClick={() => handleDivClick('in-process')}
        style={{ cursor: 'pointer', display: 'inline-block', zIndex: 9 }}
      >
        <i className="fa-thin fa-gears mx-2" />
        In Process{' '}
        <Badge bg="light" text="dark">
          {counts.inProcess}
        </Badge>
      </div>
      <div
        className={`${getClassName('active')} mr-2 activeTab`}
        onClick={() => handleDivClick('active')}
        style={{ cursor: 'pointer', display: 'inline-block', zIndex: 8 }}
      >
        <i className="fa-thin fa-flask mx-2" />
        Active Library{' '}
        <Badge bg="light" text="dark">
          {counts.active}
        </Badge>
      </div>
      <div
        className={`${getClassName('archived')} mr-2 archivedTab text-light ms-2`}
        onClick={() => handleDivClick('archived')}
        style={{ cursor: 'pointer', display: 'inline-block' }}
      >
        Archived Documents{' '}
        <Badge bg="light" text="dark">
          {counts.archived}
        </Badge>
      </div>
      <Form.Control
        type="text"
        placeholder="Search"
        className="d-inline-block w-auto ml-2 rounded-0"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value, searchType)}
      />
    </div>
  );
};

export default LifecycleBar;
