import Pictogram from 'components/Pictograms';
import { useState } from 'react';
import { ListGroup, Modal, Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Paginated-SDS-Item.css';
import { StringDisplay } from 'components/custom-attributes/String';
import { NumberDisplay } from 'components/custom-attributes/Number';
import { MultiSelectDisplay } from 'components/custom-attributes/Multi-Select';
import { languageConfig } from 'utils/languageConfig';

const highlightTextOrArray = (textOrArray, fieldMatches) => {
  if (Array.isArray(textOrArray)) {
    return highlightTextArray(textOrArray, fieldMatches);
  } else {
    return highlightText(String(textOrArray), fieldMatches);
  }
};

const highlightTextArray = (textArray, fieldMatches) => {
  return textArray.map((item, index) => {
    const itemMatches = fieldMatches.filter((match) => {
      return match.indices.some(([start, end]) => {
        const substring = match.value.substring(start, end + 1);
        return String(item).includes(substring) && substring.length >= 3;
      });
    });

    const highlightedItem = highlightText(String(item), itemMatches);

    return (
      <span key={index}>
        {highlightedItem}
        {index < textArray.length - 1 && ', '}
      </span>
    );
  });
};

const highlightText = (text, fieldMatches) => {
  if (!text || !fieldMatches || fieldMatches.length === 0) {
    return text;
  }

  const indices = fieldMatches.flatMap((match) =>
    match.indices
      .filter(([start, end]) => {
        const substring = match.value.substring(start, end + 1);
        return text.includes(substring) && substring.length >= 3;
      })
      .map(([start, end]) => [
        text.indexOf(match.value.substring(start, end + 1)),
        text.indexOf(match.value.substring(start, end + 1)) + (end - start),
      ])
  );

  if (indices.length === 0) return text;

  // Merge overlapping indices
  const mergedIndices = indices.reduce((acc, curr) => {
    if (acc.length === 0) return [curr];
    const last = acc[acc.length - 1];
    if (curr[0] <= last[1]) {
      last[1] = Math.max(last[1], curr[1]);
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  let result = [];
  let lastIndex = 0;

  mergedIndices.forEach(([start, end]) => {
    if (start > lastIndex) {
      result.push(text.substring(lastIndex, start));
    }
    result.push(
      <span key={start} className="highlighted-text">
        {text.substring(start, end + 1)}
      </span>
    );
    lastIndex = end + 1;
  });

  if (lastIndex < text.length) {
    result.push(text.substring(lastIndex));
  }

  return result;
};

const TranslationButton = ({ language, fileHash, recordId }) => {
  const navigate = useNavigate();
  const config = languageConfig[language] || { name: language, color: '#808080' };

  const handleClick = (e) => {
    e.stopPropagation();
    navigate(`/sds/${recordId}/${language}`);
  };

  return (
    <Badge
      pill
      bg={null} // This prevents Bootstrap from adding a background color class
      className="p-2"
      style={{
        backgroundColor: config.color,
        color: '#ffffff',
        cursor: 'pointer',
        fontSize: '0.8em',
        padding: '0.4em 0.8em',
        margin: '0 0.2em 0.2em 0',
        border: 'none',
        transition: 'opacity 0.2s ease-in-out',
      }}
      onClick={handleClick}
      onMouseEnter={(e) => (e.target.style.opacity = '0.8')}
      onMouseLeave={(e) => (e.target.style.opacity = '1')}
    >
      {config.name}
    </Badge>
  );
};

const PaginatedSDSItem = ({ sdsDocument, userSettings, matches, portalConfigs }) => {
  const navigate = useNavigate();
  const { productIdentifier, manName, signalWord, pictograms, photos, aka, recordId, customAttributes } = sdsDocument;
  const upperCaseSignalWord = signalWord?.toUpperCase() || '';

  const getMatchesForField = (fieldName) => {
    return matches?.filter((match) => match.key === fieldName) || [];
  };

  const renderCustomAttribute = (attribute) => {
    const defaultAttribute = portalConfigs.portal.attributeSettings.find((attr) => attr.name === attribute.name);
    const highlightedAttribute = {
      ...defaultAttribute,
      value: highlightTextOrArray(attribute.value, getMatchesForField(attribute.name)),
    };

    switch (attribute.type) {
      case 'string':
        return <StringDisplay key={attribute.name} attribute={highlightedAttribute} />;
      case 'number':
        return <NumberDisplay key={attribute.name} attribute={highlightedAttribute} />;
      case 'multi-select':
        return <MultiSelectDisplay key={attribute.name} attribute={highlightedAttribute} />;

      // Add more cases for other attribute types as needed
      default:
        return null;
    }
  };

  return (
    <ListGroup.Item action onClick={() => navigate(`/sds/${recordId}`)} className="sdsItem">
      <div className="d-flex w-100 justify-content-between align-content-start">
        <div className="w-100">
          <div className="productIdentifier mb-0 h5">{highlightText(productIdentifier, getMatchesForField('productIdentifier'))}</div>
          <small>{highlightText(manName, getMatchesForField('manName'))}</small>
        </div>
        <div className="d-flex flex-column align-items-end">
          <span className={`signalWord text-right pe-2 ${signalWord}`}>{upperCaseSignalWord}</span>
          <div className="ms-auto d-flex align-items-start float-right">
            {pictograms.map((pictogram, index) => (
              <Pictogram key={index} ghs={pictogram} />
            ))}
            {userSettings.showPhotos && photos.map((photo, index) => <ItemPhoto key={index} photoURL={photo} />)}
          </div>
        </div>
      </div>
      <div className="w-100">
        <small>{highlightTextArray(aka, getMatchesForField('aka'))}</small>
      </div>

      {customAttributes && customAttributes.length > 0 && (
        <div className="d-flex flex-wrap w-100 customAttributes justify-content-between">{customAttributes.map(renderCustomAttribute)}</div>
      )}
      {sdsDocument.translations && sdsDocument.translations.length > 0 && (
        <div className="d-flex flex-wrap w-100 translations justify-content-end">
          {sdsDocument.translations.map((translation) => (
            <TranslationButton key={translation.language} language={translation.language} fileHash={translation.fileHash} recordId={recordId} />
          ))}
        </div>
      )}
    </ListGroup.Item>
  );
};

const ItemPhoto = ({ photoURL }) => {
  const [showModal, setShowModal] = useState(false);
  const subdomain = window.location.hostname.split('.')[0];
  const imageUrl =
    process.env.REACT_APP_DEV === 'true'
      ? `${process.env.REACT_APP_API_URL}/resources/${subdomain}/${photoURL}`
      : `${process.env.REACT_APP_API_URL}/resources/${photoURL}`;

  const handleModalToggle = (e) => {
    e.stopPropagation();
    setShowModal(!showModal);
  };

  return (
    <>
      <img src={imageUrl} className="itemThumbnail" onClick={handleModalToggle} alt="Item thumbnail" />
      <Modal show={showModal} onHide={handleModalToggle} centered>
        <Modal.Body className="d-flex justify-content-center">
          <img src={imageUrl} className="img-fluid" alt="Full size item" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalToggle}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaginatedSDSItem;
