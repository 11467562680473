import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';

const ColumnVisibilityToggle = ({ columns, columnVisibility, onColumnVisibilityChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [localVisibility, setLocalVisibility] = useState(columnVisibility);
  const closeTimeoutRef = useRef(null);

  useEffect(() => {
    setLocalVisibility(columnVisibility);
  }, [columnVisibility]);

  const handleToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  const handleMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setShowDropdown(false);
    }, 2000); // 2-second delay before closing
  };

  const handleColumnVisibilityChange = (columnId) => {
    const newVisibility = {
      ...localVisibility,
      [columnId]: !localVisibility[columnId],
    };
    setLocalVisibility(newVisibility); // Update local state for immediate feedback
    onColumnVisibilityChange(newVisibility); // Update global state
  };

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Dropdown show={showDropdown} onToggle={handleToggle}>
      <Dropdown.Toggle
        variant="secondary"
        id="dropdown-column-visibility"
        onClick={() => setShowDropdown((prev) => !prev)} // Toggle dropdown on click
      >
        <FontAwesomeIcon icon={faListCheck} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        onMouseEnter={handleMouseEnter} // Keep open on hover
        onMouseLeave={handleMouseLeave} // Delay before closing
      >
        {columns
          .filter((column) => column.id !== 'select' && column.getCanHide())
          .map((column) => (
            <Dropdown.Item
              key={column.id}
              as="label"
              className="d-flex align-items-center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleColumnVisibilityChange(column.id);
              }}
            >
              <Form.Check
                type="checkbox"
                checked={localVisibility[column.id] !== false}
                onChange={(e) => {
                  e.stopPropagation();
                  handleColumnVisibilityChange(column.id);
                }}
                label={column.columnDef.header || column.id}
              />
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColumnVisibilityToggle;
