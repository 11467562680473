import React, { useState } from 'react';
import { ListGroup, Form, Badge } from 'react-bootstrap';
import HelpText from './HelpText';

const MultiSelectDisplay = ({ attribute }) => {
  const { name, value, displayStyles, displayClasses } = attribute;

  const defaultClasses = {
    component: 'multiSelectDisplay',
    label: 'multiSelect-label',
    value: 'multiSelect-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    input: {},
  };

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component || {}}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label || {}}>
        {name}:
      </div>
      <ListGroup className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value || {}}>
        {Array.isArray(value) ? (
          value.map((item, index) => <ListGroup.Item key={index}>{item}</ListGroup.Item>)
        ) : (
          <ListGroup.Item>{value}</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

const MultiSelectEdit = ({ attribute, onChange }) => {
  const { name, value, required, helpText, options, editClasses, editStyles } = attribute;

  const defaultClasses = {
    component: 'col-12 col-md-6 p-2',
    label: 'form-label fw-bold',
    input: '',
  };

  const defaultStyles = {
    component: {},
    label: {},
    input: {},
  };

  const handleToggle = (option) => {
    const newValue = Array.isArray(value) ? [...value] : [];
    const index = newValue.indexOf(option);
    if (index > -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(option);
    }
    onChange({ target: { name, value: newValue } });
  };

  return (
    <div className={editClasses?.component || defaultClasses.component} style={editStyles?.component || defaultStyles.component}>
      <Form.Label
        htmlFor={`multiSelect-edit-${name}`}
        className={editClasses?.label || defaultClasses.label}
        style={editStyles?.label || defaultStyles.label}
      >
        {name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </Form.Label>
      <ListGroup
        id={`multiSelect-edit-${name}`}
        className={editClasses?.input || defaultClasses.input}
        style={editStyles?.input || defaultStyles.input}
      >
        {options.map((option, index) => {
          const isSelected = Array.isArray(value) && value.includes(option);
          return (
            <ListGroup.Item
              key={`${option}-${isSelected}`}
              onClick={() => handleToggle(option)}
              className={isSelected ? 'active' : ''}
              style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <span>{option}</span>
              <i className={isSelected ? 'fa-regular fa-square-check' : 'fa-regular fa-square'}></i>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};

const MultiSelectCell = ({ value, columnName, onAddFilter }) => {
  if (!Array.isArray(value)) {
    return <div className="multiSelectCell">Invalid value</div>;
  }

  return (
    <div className="multiSelectCell">
      {value.map((item, index) => (
        <Badge key={index} bg="secondary" className="me-1 mb-1" onClick={() => onAddFilter(columnName, item)} style={{ cursor: 'pointer' }}>
          {item}
        </Badge>
      ))}
    </div>
  );
};

export { MultiSelectDisplay, MultiSelectEdit, MultiSelectCell };
