import { useState, useCallback } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

const useRequest = (method) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { keycloak } = useKeycloak();

  const apiURLPrefix = process.env.NODE_ENV !== 'production' ? '' : '/api';
  const subdomain = window.location.hostname.split('.')[0];

  const sendRequest = useCallback(
    async (path, data = {}, options = {}) => {
      setIsLoading(true);
      setError(null);
      try {
        const headers = {
          subdomain,
          'Content-Type': 'application/json',
          ...(keycloak.authenticated && { Authorization: `Bearer ${keycloak.token}` }),
        };

        const config = {
          method,
          url: `${apiURLPrefix}${path}`,
          headers,
          data,
          ...options,
        };

        const res = await axios(config);
        setResponse(res.data);
        return res;
      } catch (err) {
        if (err.response) {
          // Server responded with a status other than 2xx
          setError({
            message: err.response.data.message || 'Server Error',
            status: err.response.status,
            data: err.response.data,
          });
        } else if (err.request) {
          // Request was made but no response received
          setError({ message: 'No response from server. Please try again later.' });
        } else {
          // Something else caused the error
          setError({ message: err.message });
        }
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [apiURLPrefix, keycloak, subdomain, method]
  );

  return { sendRequest, response, isLoading, error };
};

export default useRequest;
