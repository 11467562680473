import React from 'react';
import { flexRender } from '@tanstack/react-table';

const TableHeader = ({ headerGroups }) => (
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <th key={header.id} className="align-middle" style={{ textAlign: header.column.columnDef.textAlign || 'left' }}>
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        ))}
      </tr>
    ))}
  </thead>
);

export default TableHeader;
